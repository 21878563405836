import gqlClient from '../gqlClient'

export const getOrderDetail = ({ date, meals }) =>
  gqlClient
    .query(
      `query getOrderDetail($date: String!, $meals: [MealInput]) {
        getOrderDetail(date: $date, meals: $meals) {
          qtyPlanMeals,
          totalPlanPrice,
          totalExtraMeals,
          totalTaxes,
          totalDeliveryFee,
          subTotalOrder,
          totalPromoDiscount,
          totalOrder,
          availableCredits,
          totalOrderWithCreditsSubtracted,
          membershipDiscount {
            total,
            delivery,
            meals,
            promotions
          }
        }
      }
    `,
      { date, meals }
    )
    .then(response => {
      return response.data.getOrderDetail
    })
