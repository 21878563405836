export interface Benefit {
  benefit: string
  detail: string
}

export interface MembershipData {
  activation: MembershipActivation
  membership: Membership
}

export interface Membership {
  id?: number | null
  name?: string | null
  cost?: number
  active?: boolean | null
  freeDelivery?: boolean | null
  premiumMeals?: number | null
  addAndSave?: boolean | null
}

export interface MembershipActivation {
  membershipId: number | null
  active: boolean | null
  startDate: string | null
  endDate: string | null
  stopDate: string | null
  accumulatedSavings: number | null
}

export type Step =
  | 'introduction'
  | 'benefits'
  | 'checkout'
  | 'confirmation'
  | 'cancel'
  | 'closed'

export interface State {
  isOpen: boolean
  membership: Membership[]
  inFlight: boolean
  error: boolean
  succeded: boolean
}

export interface MembershipType {
  type: Membership
  payload?: {
    membership?: number | string
  }
}

export enum MembershipOverlayName {
  introduction = 'UnityPass Introduction',
  benefits = 'UnityPass Benefits',
  checkout = 'UnityPass Checkout',
  welcome = 'UnityPass Welcome',
  cancel = 'UnityPass Cancel'
}

export enum MembershipOverlayActions {
  discover = 'Discover Unity Pass benefits',
  cancel = 'Cancel Unity Pass',
  noThanks = 'No Thanks',
  joinNow = 'Join now',
  confirm = 'Confirm purchase',
  nevermind = 'Never mind',
  yesCancelNow = 'Yes, cancel now'
}
