import { useIsMobile } from 'src/hooks/useIsMobile'
import { CuiModal, CuiBottomSheet } from 'front-lib'

interface Props {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  className?: string
}

/**
 * Useful when you need the pattern: Modal for desktop and BottomSheet for mobile.
 */
export function ResponsiveModalOrBottomSheet({
  isOpen,
  onClose,
  children,
  className = ''
}: Props) {
  const isMobile = useIsMobile()

  if (isMobile) {
    return (
      <CuiBottomSheet isOpen={isOpen} className={className}>
        <CuiBottomSheet.Header onRequestClose={onClose} />
        <CuiBottomSheet.Body>{children}</CuiBottomSheet.Body>
      </CuiBottomSheet>
    )
  }
  return (
    <CuiModal isOpen={isOpen} onRequestClose={onClose} className={className}>
      {children}
    </CuiModal>
  )
}
