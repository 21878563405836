import { useEffect, useState } from 'react'
import { useFlag } from '@unleash/proxy-client-react'
import { useSetAtom } from 'jotai'

import { FF_SKIP_DEFLECTION_WITH_PROMO } from 'src/constants/feature-flags'
import { useGetAvailableCreditsAndPromotions } from 'src/api/creditsAndPromotions/getAvailableCreditsAndPromotions'
import { deflectionWithActivePromoModalAtom } from 'src/atoms/modals/deflectionWithPromoActiveModal.atom'
import { PromotionType } from 'src/types/promotion'

export function useDeflectionWithPromoStrategy() {
  const [creditsAndPromotions, setCreditsAndPromotions] = useState({
    credits: '0',
    promotions: []
  })
  const featureEnabled = useFlag(FF_SKIP_DEFLECTION_WITH_PROMO)
  const {
    getCreditsAndPromotions,
    credits,
    promotions
  } = useGetAvailableCreditsAndPromotions()
  const setDeflectionWithActivePromoModal = useSetAtom(
    deflectionWithActivePromoModalAtom
  )
  const VALID_PROMOTIONS: PromotionType[] = [
    'discountPromotion',
    'creditPromotion'
  ]

  useEffect(() => {
    if (featureEnabled) {
      getCreditsAndPromotions()
    }
    // eslint-disable-next-line
  }, [featureEnabled])

  useEffect(() => {
    let creditsAndPromotionsAvailable = {}

    if (credits) {
      creditsAndPromotionsAvailable = {
        ...creditsAndPromotionsAvailable,
        credits
      }
    }

    if (promotions.length > 0) {
      const activePromotions = promotions.filter(isValidPromotion)

      creditsAndPromotionsAvailable = {
        ...creditsAndPromotionsAvailable,
        promotions: activePromotions
      }
    }

    setCreditsAndPromotions({
      ...creditsAndPromotions,
      ...creditsAndPromotionsAvailable
    })
    // eslint-disable-next-line
  }, [credits, promotions])

  function isValidPromotion(promotion: {
    active: boolean
    promotionType: PromotionType
  }) {
    return (
      promotion.active && VALID_PROMOTIONS.includes(promotion.promotionType)
    )
  }

  function triggerStrategy({
    type,
    payload = {},
    onContinue = () => {}
  }: {
    type: 'skip' | 'pause' | 'cancel'
    payload: any
    onContinue: () => void
  }) {
    const { credits, promotions } = creditsAndPromotions
    const noPromos = promotions.length === 0
    const noCredits = credits === '0'

    const noDeflectionStrategy = noPromos && noCredits
    // the startegy for pause and skip only applies if user has promotion
    // credits are not included in this strategies
    const noStrategyForSkipAndPause =
      noPromos && (type === 'skip' || type === 'pause')

    if (noDeflectionStrategy || noStrategyForSkipAndPause) {
      onContinue()
      return
    }

    setDeflectionWithActivePromoModal({
      isOpen: true,
      type,
      payload,
      credits,
      promotions
    })
  }

  return {
    enabled: featureEnabled,
    triggerStrategy
  }
}
