import { useEffect } from 'react'
import { CuiButton, CuiSpinner } from 'front-lib'

import { useTrackMembershipModal } from '../../useTrackMembershipModal'
import { MEMBERSHIP_MODAL_STEPS } from 'src/modules/Modals/constants'
import { useStopMembership } from 'src/api/Membership/stopMembership'
import { formatDate } from 'src/components/DeliveryCard/utils'
import {
  MembershipData,
  MembershipOverlayActions,
  MembershipOverlayName,
  Step
} from 'src/types/membership'
import { toaster } from 'src/utils/toaster'

import './MembershipCancel.scss'

interface Props {
  onClose: () => void
  refetch: () => void
  onContinue: (step: Step) => void
  membershipData?: MembershipData
}

export function MembershipCancel({
  onClose,
  refetch,
  onContinue,
  membershipData
}: Props) {
  const { trackOptionClicked, trackModalViewed } = useTrackMembershipModal({
    overlayName: MembershipOverlayName.cancel
  })
  const { stopMembership, loading } = useStopMembership({
    onCompleted,
    onError
  })

  useEffect(() => {
    trackModalViewed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onStopMembership() {
    if (membershipData?.activation.membershipId) {
      stopMembership({ membershipId: membershipData?.activation.membershipId })
      trackOptionClicked({
        overlayAction: MembershipOverlayActions.yesCancelNow
      })
    }
  }

  function onCompleted(response) {
    if (response.stopMembership.success) {
      refetch()
      onContinue(MEMBERSHIP_MODAL_STEPS.benefits)
    } else {
      onError()
    }
  }

  function onError() {
    toaster.error('Sorry, we encountered an error. Please try again.')
  }

  return (
    <>
      <div className="unity-pass-banner" />
      <div className="cancel-container">
        {loading && (
          <div className="spinner-overlay">
            <CuiSpinner width="24px" />
          </div>
        )}
        <div className="title-container">
          <span className="title">
            Are you sure you want to give up your Unity Pass perks?
          </span>
        </div>
        <div className="description-container">
          <span>
            You’re currently enjoying no delivery fee, exclusive discounts, and
            three premium meals per order a month at no extra fee. By canceling
            now, you will lose these benefits after{' '}
            {formatDate(membershipData?.activation.endDate)}
          </span>
          <span className="accumulated">
            Total Unity Pass savings to date: $
            {membershipData?.activation.accumulatedSavings || 0}
          </span>
        </div>
        <div className="button-container">
          <CuiButton className="secondary-button" onClick={onStopMembership}>
            Yes, cancel now
          </CuiButton>
          <CuiButton className="primary-button" onClick={() => onClose()}>
            Never mind
          </CuiButton>
        </div>
      </div>
    </>
  )
}
