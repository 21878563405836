import { gql, useLazyQuery } from '@apollo/client'
import { PromotionType } from 'src/types/promotion'

interface Promotion {
  promotionActivationId: number
  promotionType: PromotionType
  type: string
  offer: string
  status: string | null
  expirationDate: string | null
  feeForLife: boolean
  active: boolean
}

export const GET_AVAILABLE_CREDITS_AND_PROMOTIONS_QUERY = gql`
  query getAvailableCreditsAndPromotions {
    creditsAndPromotionsPagePromotionsTab {
      promotions {
        promotionActivationId
        promotionType
        type
        offer
        status
        expirationDate
        feeForLife
        active
      }
    }
    creditsAndPromotionsPageCreditsResume {
      creditsAvailable
    }
  }
`

export function useGetAvailableCreditsAndPromotions() {
  const [getCreditsAndPromotions, { data, ...rest }] = useLazyQuery<{
    creditsAndPromotionsPagePromotionsTab: {
      promotions: Promotion[]
    }
    creditsAndPromotionsPageCreditsResume: {
      creditsAvailable: string
    }
  }>(GET_AVAILABLE_CREDITS_AND_PROMOTIONS_QUERY, {
    fetchPolicy: 'cache-and-network'
  })

  return {
    getCreditsAndPromotions,
    credits:
      data?.creditsAndPromotionsPageCreditsResume?.creditsAvailable || '0',
    promotions: data?.creditsAndPromotionsPagePromotionsTab?.promotions || [],
    ...rest
  }
}
