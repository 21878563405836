import { gql, useMutation } from '@apollo/client'

export const STOP_MEMBERSHIP = gql`
  mutation stopMembership($membershipId: Int!) {
    stopMembership(membershipId: $membershipId) {
      success
      error
    }
  }
`

export function useStopMembership({
  onCompleted = () => {},
  onError = () => {}
}: {
  onCompleted?: (response?: any) => void
  onError?: () => void
}) {
  const [stopMembership, rest] = useMutation(STOP_MEMBERSHIP, {
    onCompleted,
    onError,
    notifyOnNetworkStatusChange: true
  })

  return {
    stopMembership: (variables: { membershipId: number | null | undefined }) =>
      stopMembership({ variables }),
    ...rest
  }
}
