import { formatDate } from 'src/components/DeliveryCard/utils'

export function RejoinFooter({ endDate }) {
  return (
    <div className="footer-container">
      <span className="rejoin">
        Continue enjoying your benefits till {formatDate(endDate)}
      </span>
    </div>
  )
}
