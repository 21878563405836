import { gql, useMutation } from '@apollo/client'

export const START_MEMBERSHIP = gql`
  mutation startMembership($membershipId: Int!) {
    startMembership(membershipId: $membershipId) {
      success
      error
    }
  }
`

export function useStartMembership({
  onCompleted = () => {},
  onError = () => {}
}: {
  onCompleted?: (response?: any) => void
  onError?: () => void
}) {
  const [startMembership, rest] = useMutation(START_MEMBERSHIP, {
    onCompleted,
    onError,
    notifyOnNetworkStatusChange: true
  })

  return {
    startMembership: (variables: { membershipId: number }) =>
      startMembership({ variables }),
    ...rest
  }
}
