import { useVariant } from '@unleash/proxy-client-react'
import { useSelector } from 'react-redux'

import { MEMBERSHIP_MODAL_WHITELIST } from 'src/constants/feature-flags'
import { getUserId } from 'src/redux-api-bridge/account/selectors'

export function useMembershipWhitelist() {
  const membershipVariant = useVariant(MEMBERSHIP_MODAL_WHITELIST)
  const userId = useSelector(getUserId)

  if (membershipVariant.enabled && membershipVariant.payload?.value) {
    const membershipWhitelist = JSON.parse(membershipVariant.payload?.value)
    return membershipWhitelist.userIds.includes(userId)
  }

  return false
}
