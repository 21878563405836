import { gql, useQuery } from '@apollo/client'
import { MembershipData } from 'src/types/membership'

export const GET_MEMBERSHIP_QUERY = gql`
  query UserMembershipActivation {
    userMembershipActivation {
      membership {
        id
        name
        cost
        active
        freeDelivery
        premiumMeals
        addAndSave
      }
      activation {
        membershipId
        active
        startDate
        endDate
        stopDate
        accumulatedSavings
      }
    }
  }
`

export function useMembershipData() {
  const { data, ...rest } = useQuery<{
    userMembershipActivation: MembershipData
  }>(GET_MEMBERSHIP_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first'
  })
  return {
    membershipData: data?.userMembershipActivation,
    ...rest
  }
}
